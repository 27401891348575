@import './shared';
// @import '~antd/dist/antd.dark.css';

h1, h2, h3,
h4, h5, h6 {
	@extend %headings !optional;
}

body {
	margin: 0;
	padding: 0;
	font-size: 1.2rem;
	font-family: "PT Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
	background-color: rgba(0, 0, 0, 0.1);
	padding: 0.15em 0.25em;
	border-radius: 0.25em;
}

.section-heading {
	font-size: 2.25rem;
	padding-bottom: 1rem;
}

%headings {
	font-family: 'PT Serif';
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 10em;

	&:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}
}
